import React from "react";
import Layout from "../components/Layout";
import EmailSignup from "../components/EmailSignup";
import Birthstones from "../components/Birthstones";
import { Helmet } from "react-helmet";

function BirthstoneNumbers() {
  return (
    <>
      <Helmet>
        <title>Birthstone Lottery Numbers - Lottery Angels</title>
        <meta
          name="description"
          content="Select your birthstone to see daily lottery number predictions based on your birth month's gemstone. Find lucky numbers for Pick 3 and Pick 4 games."
        />
      </Helmet>
      <Layout>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">
            Birthstone Lottery Numbers
          </h1>
          <div className="bg-white p-6 rounded-lg shadow-md mb-8">
            <p className="text-2xl font-bold mb-4">
              Select your birthstone below to see your birthstone lottery
              numbers for today.
            </p>
          </div>

          <Birthstones />

          <EmailSignup />
        </div>
      </Layout>
    </>
  );
}

export default BirthstoneNumbers;
